/* styles.css */
@font-face {
    font-family: 'jf2.0';
    font-weight: normal;
    src: local('jf2.0'), url(../fonts/jf/jf-openhuninn-2.0.ttf) format('truetype');
}

@font-face {
    font-family: 'GenSanRounded';
    font-weight: normal;
    src: local('GenSanRounded'), url(../fonts/GenSenRounded/GenSenRounded-R.ttc) format('truetype');
}

@font-face {
    font-family: 'GenSanRounded-B';
    src: local('GenSanRounded'), url(../fonts/GenSenRounded/GenSenRounded-B.ttc) format('truetype');
}

:root{
    font-family: 'GenSanRounded', sans-serif;
    color: rgb(44, 64, 87);
}

.subtitle{
    font-size: 22px;
    font-weight: bold;
}

body {
    background-color: rgb(250, 250, 244);
    margin: 0;
}

h2{     /*首頁實驗室標題*/
    font-size: 50px;
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
    color: rgb(255, 255, 255);
    font-family: 'GenSanRounded';
    font-weight: bold;
}

h3{
    font-size: 50px;
    color: rgb(44, 64, 87);
    font-family: 'GenSanRounded';
}

h4{
    font-size: 40px;
    color: rgb(44, 64, 87);;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

h5{
    font-size: 32px;
    color: rgb(44, 64, 87);
    font-family: 'GenSanRounded';
}

h6{
    font-size: 22px;
    color: rgb(44, 64, 87);
    font-family: 'GenSanRounded', 'Gill Sans';
}

p{
    font-size: 20px;
    color: rgb(44, 64, 87);
    font-family: 'GenSanRounded';
    margin-top: 5px;
}

.welcome-left{ /*實驗室首頁左半邊字體(實驗室名稱)*/
    color: rgb(255, 255, 255);
    margin-block-start: 0.7em;
    margin-block-end: 0.7em;
    font-size: 22px;
    font-weight: normal;
}

.welcome-right{ /*實驗室首頁右半邊字體(實驗室介紹)*/
    color: rgb(15,76,129);
    margin-block-start: 0.7em;
    margin-block-end: 0.7em;
    font-size: 23px;
    font-weight: bold;
    text-shadow:
    -1px -1px 0 rgb(255, 255, 255),  
    1px -1px 0 rgb(255, 255, 255),
    -1px 1px 0 rgb(255, 255, 255),
    1px 1px 0 rgb(255, 255, 255);
    padding: 0 5%;
}

.hint{
    font-size: 15px;
}

.footer {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

.cover {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 預設為兩欄佈局 */
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-image: url('/public/images/indexBG02.jpg');
    overflow: auto;
}

.left-half, .right-half {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* 讓內容居中 */
    padding: 20px; /* 增加內邊距 */
}

/* 左半部分樣式 */
.left-half {
    background-color: rgba(100, 100, 100, 0.8); /* 左邊灰色半透明背景 */
    text-align: center;
}

/* 右半部分樣式 */
.right-half {
    background-color: rgba(255, 255, 255, 0.7); /* 右邊半透明白色背景 */
    text-align: left;
}

/* 響應式佈局：當螢幕寬度小於768px時變為單欄佈局 */
@media (max-width: 768px) {
    .cover {
        grid-template-columns: 1fr; /* 單欄佈局 */
        grid-template-rows: auto auto; /* 自動調整高度 */
    }
}

#title{
    text-align: 'center'; 
    font-size: '42px'
}

.header{
    Box{
        position: sticky;
        top: 0;
        z-index: -1;
        border-bottom: 1;
        border-color: #364f3b;
        background-color: #ffffff;
        height: 10vh;
        display: flex;
        align-items: center;
    }
}